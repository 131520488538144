// Switching tabs (animations)
$(document).on("click", ".ews-menu .mdc-tab", function (e) {
  let $menu = $(this).closest('.ews-menu'),
    $icon = $(this).find('.mdc-tab__icon'),
    activeClass = 'material-icons',
    defaultClass = 'material-icons-outlined';

  $menu.find('.mdc-tab__icon').addClass(defaultClass).removeClass(activeClass);
  $icon.addClass(activeClass).removeClass(defaultClass);
});

// Global search input - expand over logo
$(document).on('focusin', '.ews-g-search__input', function () {
  $(this).closest('.ews-g-search__form').addClass('ews-g-search__form--active');
});

// Remove active state if clicking outside search container
$(document).on('click', function (e) {
  let container = $(".ews-g-search__form");

  if (!container.is(e.target) && container.has(e.target).length === 0) {
    $('.ews-g-search__form').removeClass('ews-g-search__form--active');
  }
});

// Mega menu search / item filter
window.menuSearchTimer = 0;
$(document).on('keyup', 'input#menuSearch', function () {
  let input = this;

  clearTimeout(window.menuSearchTimer);
  window.menuSearchTimer = setTimeout(function () {
    resultSearch(input);
  }, 250);
});

function resultSearch(input) {
  let $input = $(input);
  if (!$input.length) return;
  let searchQuery = $input.val().trim().toLowerCase(),
      $nav = $('.menu .menu__nav');

  // Load all submenu and hide menu items which toggle them
  loadSubmenusOnSearch($nav, searchQuery, function () {
    filterMenuItems($nav, searchQuery);
  });
}

function filterMenuItems($nav, searchQuery) {
  if (!$nav) return;

  $nav.find('.menu__item:not(.menu__item-submenu)').filter(function () {
    // Title + Subtitle
    let text = [
      $(this).find('.menu__item-title').text(),
      $(this).find('.menu__item-subtitle').text(),
      $(this).attr('data-search')
    ].join(' ').toLowerCase();

    $(this).toggleClass('cb--hidden', text.indexOf(searchQuery) == -1);
  });

  // Toggle whole group if no items visible;
  $nav.find('.menu__group').filter(function () {
    let items = $(this).find('.menu__item:not(.menu__item-submenu):not(.cb--hidden)');
    $(this).toggleClass('cb--hidden', items.length == 0);
  });

  // Toggle no records found
  let recordsLength = $nav.find('.menu__item:not(.menu__item-submenu):not(.cb--hidden)').length;

  if (recordsLength == 0) {
    if ($nav.find('.menu__no-records').length) {
      $nav.find('.menu__no-records').removeClass('cb--hidden');
    } else {
      $nav.append(
        $(`<div class="menu__no-records">
        <i class="material-icons">search_off</i>
        <div class="menu__no-records-title">${I18n.t("menu.no_items_found.title")}</div>
        <div class="menu__no-records-subtitle">${I18n.t("menu.no_items_found.subtitle")}</div>
        </div>`)
      );
    }
  } else {
    $nav.find('.menu__no-records').addClass('cb--hidden');
  }
}

function loadSubmenusOnSearch($nav, searchQuery, callback_fn) {
  let $submenuTogglers = $(".ews-header .menu .menu__item-submenu"),
      isSearch = !!searchQuery.length,
      promisesArr = [];

  // Toggle `submenu` menu items
  $submenuTogglers.toggleClass('cb--hidden', isSearch);

  // Hide all submenus if searchQuery is empty
  $nav.find('.menu__group[data-submenu]').toggleClass('menu__group--hidden', !isSearch);

  // Load submenus on first search
  $submenuTogglers.each(function (idx, el) {
    let $el = $(el),
        submenuKey = $el.attr('data-submenu');

    // Skip if already loaded
    if ($nav.find(`.menu__group[data-submenu="${submenuKey}"]`).length) return;

    let promise = new Promise((resolve, reject) => {
      let targetUrl = $el.attr('data-target');
      loadSubmenuItems(targetUrl, function (data) {
        let $newMenu = $(data);

        // Show group title
        $newMenu.find('.menu__group-title.cb--hidden').removeClass('cb--hidden');

        // Add submenu to main menu
        $nav.append($newMenu.find('.menu__nav > .menu__group'));

        // Resolve promise
        resolve();
      });
    });

    // Add submenu promise to all array
    promisesArr.push(promise);
  });

  // Filter items, when all submenus loaded
  Promise.all(promisesArr).then((values) => {
    if (typeof callback_fn === "function") callback_fn();
  });
}


// Don't close menu when clicking inside menu (search, submenu, marking text etc.)
$(document).on('click', '.ews-header .dropdown-menu', function (e) {
  e.stopPropagation();
});

// Focus menu search when the menu is opened
$(document).on('shown.bs.dropdown', '.ews-menu', function () {
  $("#menuSearch").trigger('focus');
})

// Mega menu - submenu toggle
$(document).on("click", ".ews-header .menu .menu__item-submenu", function (e) {
  let $item = $(this),
    $menu = $item.closest('.menu'),
    $menuWrapper = $item.closest('.menu__wrapper'),
    target = $item.attr('id') + 'Submenu',
    $submenu = $menu.find('#' + target);

  $menu.css({ height: $menuWrapper.outerHeight() });

  loadSubmenuItems($item.attr('data-target'), function (data) {
    let $newMenu = $(data),
      $newMenuWrapper;

    if ($submenu.length) {
      $newMenuWrapper = $submenu.replaceWith($newMenu);
    } else {
      $newMenuWrapper = $menu.append($newMenu);
    };

    $newMenuWrapper.ready(function () {
      $menuWrapper.addClass('menu__wrapper--inactive');
      $newMenu.addClass('menu__wrapper--active');
      $menu.css({ width: $newMenu.find('.menu__nav').outerWidth() + 34 });
    });

    // Set new menu height
    $menu.css({ height: $newMenu.outerHeight() });

    // Set wrapper id
    $newMenu.attr('id', target);
  });
});

function loadSubmenuItems(targetUrl, callback_fn) {
  $.ajax({
    url: targetUrl,
    method: 'GET',
    success: function (data) {
      if (typeof callback_fn === "function") callback_fn(data);
    }
  });
}

$(document).on("click", ".ews-header .menu .menu__header-back-button", function (e) {
  let $menu = $(this).closest('.menu'),
    $menuWrapper = $(this).closest('.menu__wrapper'),
    $newMenu = $menu.find('.menu__wrapper--inactive');

  $menu.css({ height: $newMenu.outerHeight() });
  $menuWrapper.removeClass('menu__wrapper--active');
  $newMenu.removeClass('menu__wrapper--inactive');

  if ($menu.hasClass('menu--1col') || window.innerWidth < 840) {
    $menu.css({ width: 374 });
  } else {
    $menu.css({ width: 686 });
  }

  $menuWrapper.on('transitionend MSTransitionEnd webkitTransitionEnd oTransitionEnd', function () {
    $(this).remove();
  });
});

// History & saved items sticky menu (offsets)
$(document).on("page:change", function () {
  let lastScrollTop = 0,
    $wrapper = $('.ews-header .menu .menu__nav-wrapper'),
    $historyMenu = $wrapper.find('.menu__history'),
    $mainMenu = $wrapper.find('.menu__nav'),
    offsetHeight = 0;

  $wrapper.historyOffset = () => $(this).find('.menu__history-offset');

  $wrapper.scroll(function () {
    if (window.innerWidth < 840) { return; }

    let lastDirrection, direction, historyMenuOpts,
      scrollTop = $(this).scrollTop(),
      wrapperHeight = $wrapper.innerHeight(),
      historyMenuHeight = $historyMenu.innerHeight(),
      mainMenuHeight = $mainMenu.innerHeight(),
      stickyOffset = wrapperHeight - historyMenuHeight,
      maxOffset = $wrapper.prop('scrollHeight') - historyMenuHeight,
      newOffset = 0;

    if (stickyOffset < 0) {
      if (scrollTop > lastScrollTop) {
        // Down
        direction = 'down';
        if (lastDirrection != direction) {
          newOffset = scrollTop;
          if (offsetHeight > newOffset) offsetHeight = newOffset;
          historyMenuOpts = { 'top': stickyOffset, 'bottom': '' };
        }

      } else {
        // Up
        direction = 'up';
        if (lastDirrection != direction) {
          newOffset = scrollTop + stickyOffset
          if (offsetHeight < newOffset) offsetHeight = newOffset;
          historyMenuOpts = { 'top': '', 'bottom': stickyOffset };
        }
      }

      changeMenuHeightAndOffset($wrapper, offsetHeight, maxOffset);
      lastDirrection = direction;

      // Change sticky dirrection
      $historyMenu.css(historyMenuOpts);
    } else {
      $historyMenu.css({ 'top': 0 });
    }

    lastScrollTop = scrollTop;
  });
});

function changeMenuHeightAndOffset($wrapper, newOffset, maxOffset) {
  let $historyOffsetBlock = $wrapper.historyOffset();

  if (newOffset < 0) { newOffset = 0 }
  if (newOffset > maxOffset) { newOffset = maxOffset };
  $historyOffsetBlock.css({ height: newOffset.toFixed() + 'px' });
}


// Stared items
$(document).on('click', '.usi-star', function (e) {
  e.stopPropagation();
  e.preventDefault();

  let $star = $(this),
    url = $star.attr('data-url'),
    method = $star.attr('data-method'),
    icon;

  $.ajax({
    url: url,
    dataType: 'JSON',
    method: method,
    success: function (data) {
      let title_attr;
      // Toggle url/method to star this resource again or remove just saved item


      // Title/Tooltip
      if ($star.attr('data-toggle') == 'tooltip') {
        title_attr = 'data-original-title'
      } else {
        title_attr = 'title'
      }

      // Change icon
      if (data.method == 'delete') {
        icon = 'star'
        toggle_class = true
      } else {
        icon = 'star_outline'
        toggle_class = false
      }

      $('.usi-star[data-for=' + $star.attr('data-for') + ']').each(function (idx, el) {
        let $el = $(el);
        $el.attr('data-url', data.url);
        $el.attr('data-method', data.method);
        $el.attr(title_attr, data.title);
        $el.text(icon);
        $el.toggleClass('usi-star--on', toggle_class);
      });

    },
    error: function (data) {
      console.log(data);
    }
  });
});
